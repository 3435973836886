<template>
  <v-container fluid>
    <v-card>
      <v-card-text class="ma-0 pa-0">
        <v-tabs v-model="tabs">
          <v-tab>
            {{ this.$t('generic.lang_general') }}
          </v-tab>

          <v-tab>
            {{ this.$t('erp.lang_productImage') }}
          </v-tab>
          <v-tab>
            {{ this.$t('erp.lang_warecreate_wareattr') }}
          </v-tab>
          <v-tab key="controlsystem">
           {{ $t('erp.lang_warecreate_waremanage') }}
          </v-tab>
          <v-tab key="info">
            {{$t('generic.lang_notes')}}
          </v-tab>
          <v-tab key="comp" v-if="this.is_IngredientComponent2==true">
             {{$t('erp.lang_subingredient')}}
          </v-tab>
        </v-tabs>

        <v-form ref="form">
          <v-tabs-items class="pa-2" v-model="tabs">
            <v-tab-item class="pa-3">
              <v-form lazy-validation ref="general">
                <v-row>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  @focus="showTouchKeyboard"
                                  :label="this.$t('erp.lang_ware_create_id')"
                                  autofocus
                                  outlined
                                  v-model="form.ware_ID"
                                  @change="checkEAN"
                                  @blur="checkEAN"
                                  :rules="[v => !!v  , this.duplicatedEAN || $t('erp.lang_barcodeTaken')]"
                                  :loading="loadingCheck"
                    >
                      <v-btn :disabled="this.loadingEAN" :loading="this.loadingEAN"
                             @click="createEAN"
                             class="ma-0 pa-0" icon slot="append"
                             style="margin: -7px -6px 0 0 !important">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <div style="margin-top:-5px;">
                              <font-awesome-icon :icon="['fal' , 'barcode']"
                                                 class="ma-0"
                                                 size="2x"
                                                 v-on="on"/>
                            </div>
                          </template>
                          <span>{{ $t('generic.lang_edit') }}</span>
                        </v-tooltip>
                      </v-btn>

                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  :label="this.$t('erp.lang_posItemName')" :rules="[v => !!v]"
                                  outlined
                                  v-model="form.ware_name"/>
                  </v-col>

                  <v-col cols="12" md="4" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  :label="this.$t('erp.internal_wareNo')"
                                  :loading="loadingCheckInternID"
                                  :rules="[ this.duplicatedInternID || $t('erp.lang_duplicatedInternalId')]"
                                  @change="checkInternID"
                                  @blur="checkInternID"
                                  outlined
                                  v-model="form.ware_internalNo"/>
                  </v-col>

                  <v-col cols="12" md="4"  sm="4">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NUMERIC" @focus="showTouchKeyboard"
                                  :label="this.$t('erp.lang_netPurchasePricePerUnit')"
                                  :rules="[v => !!v]"
                                  outlined
                                  type="text"
                                  @keypress="decimalNumbers($event, form.ware_buypriceNet)"
                                  v-model="form.ware_buypriceNet"/>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  :label="this.$t('erp.langWareWarehouseQTY')"
                                  outlined
                                  v-model="form.editItemStorageQTY"/>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">

                    <v-select
                        :items="units" item-text="unit_name" item-value="id"
                        :label="$t('erp.lang_purchaseUnit')"
                        outlined
                        v-model="form.unitOfPurchase"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" sm="4">
                    <v-select
                        :items="units" item-text="unit_name" item-value="id"
                        :label="$t('erp.lang_salesunit')"
                        outlined
                        v-model="form.SellUnit"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" sm="4">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_saleUnitValue')"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  outlined
                                  required
                                  v-model="form.sellUnitValue"
                                  min="1"
                                  type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <v-flex md12 mr-2 ml-2>
                      <v-autocomplete
                          :label="this.$t('erp.lang_ingredientGroups')"
                          :readonly="false"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          @click:append.stop="showIngredientGroup"
                          append-icon="add"
                          item-text="name"
                          item-value="uuid"
                          :items="IngredientGroups"
                          dense
                          deletable-chips
                          outlined v-model="form.ingredientGroup"
                      >
                        <template v-slot:prepend-inner>
                          <v-icon>arrow_drop_down</v-icon>
                        </template>
                        <template v-slot:append>
                          <v-menu
                              :close-on-content-click="false"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              @focus="showTouchKeyboard"
                              :nudge-width="200"
                              id="menu_create"
                              max-width="304"
                              offset-y
                              transition="slide-x-transition"
                              v-model="showIngredientCreate"
                              z-index="10"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn @click="showIngredientGroup" color="primary" class="pt-0"
                                     fab
                                     id="icon-wiregroup-add" small
                                     v-on="on">
                                <v-icon>add</v-icon>
                              </v-btn>
                            </template>
                            <v-card outlined>
                              <v-row align="center"
                                     class="dropdown-menu-header"
                                     justify="center">
                                <div class="dropdown-menu-header-inner bg-primary"
                                >
                                  <div class="menu-header-image dd-header-bg-1"></div>
                                  <div class="menu-header-content text-center pa-3 ma-0"
                                  >
                                    <div class="text-center"><h5
                                        class="menu-header-title ">
                                      {{ $t('erp.lang_ingredientGroupsAdd') }}</h5>
                                      <span class="mx-auto text-center">
                                              {{ $t('erp.lang_addIngredientGroupText') }}
                                            </span>
                                    </div>
                                  </div>
                                </div>
                              </v-row>
                              <div class="scroll-area-xl pa-2">
                                <v-form class="ma-0 pa-0"
                                        lazy-validation
                                        onsubmit="return false"
                                        ref="garnishGroupFrom">

                                  <v-row justify-md="center">
                                    <v-col cols="12">
                                      <v-text-field v-model="newIngredientGroup.name"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                    :label="$t('erp.lang_ingredientGroupName')"
                                                    autocomplete="off"
                                                    :rules="[v=>!!v && v.length>0 || $t('generic.lang_requiredField')]"
                                                    outlined
                                                    required
                                                    @focus="showTouchKeyboard"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <fontawesome-picker :fieldLabel="$t('generic.lang_icon')"
                                                          v-model="newIngredientGroup.icon"/>
                                    </v-col>
                                    <v-col cols="12">
                                      <div class="widget-content text-left pa-0">
                                                                        <span class="font-weight-medium">{{
                                                                            $t('erp.lang_warecreate_color')
                                                                          }}:</span>
                                        <swatches
                                            v-model="newIngredientGroup.color" inline
                                            background-color="transparent"
                                        ></swatches>
                                      </div>
                                    </v-col>
                                  </v-row>

                                  <v-divider class="pa-0 ma-0"/>
                                  <div class="text-center">
                                    <button @click="showIngredientCreate = false"
                                            class="btn btn-danger ma-2">
                                      {{ $t('generic.lang_cancel') }}
                                    </button>

                                    <v-btn color="primary"
                                           @click="createIngredientGroups"
                                           :loading="loadingCreatIngredientGroup"
                                           :disabled="newIngredientGroup.name===''||newIngredientGroup.name===null">
                                      {{ $t('generic.lang_create') }}
                                    </v-btn>
                                  </div>
                                </v-form>
                              </div>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-col>
                  <!--
                                    <v-col class="text-center" cols="12">
                                      <v-btn-toggle
                                          class="mx-auto"
                                          mandatory
                                          v-model="form.gastroFoodType"
                                      >
                                        <v-btn value="1" x-large>
                                          <font-awesome-icon :icon="['fal' , 'cocktail']" class="primary&#45;&#45;text"
                                                             size="3x"/>
                                        </v-btn>
                                        <v-btn value="2" x-large>
                                          <font-awesome-icon :icon="['fal' , 'meat']" class="primary&#45;&#45;text"
                                                             size="3x"/>
                                        </v-btn>

                                        <v-btn value="3" x-large>
                                          <font-awesome-icon :icon="['fal' , 'gifts']" class="primary&#45;&#45;text"
                                                             size="3x"/>
                                        </v-btn>
                                      </v-btn-toggle>
                                    </v-col>-->
                  <v-col cols="12" sm="4" class="pt-0">
                    <strong>{{ $t('erp.mealType') }}</strong><br>
                    <v-radio-group class="mx-auto" v-if="form.gastroFoodType" row
                                   v-model="form.gastroFoodType">
                      <v-radio
                          :key="0"
                          :value="1"
                      >
                        <template slot="label">
                          <font-awesome-icon :icon="['fal', 'cocktail']" size="3x"/>
                        </template>
                      </v-radio>

                      <v-radio
                          :key="1"
                          :value="2"
                      >
                        <template slot="label">
                          <font-awesome-icon :icon="['fal', 'meat']" size="3x"/>
                        </template>
                      </v-radio>

                      <v-radio
                          :key="2"
                          :label="`Radio`"
                          :value="3"
                      >
                        <template slot="label">
                          <font-awesome-icon :icon="['fal', 'gifts']" size="3x"/>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox
                        v-model="form.is_IngredientComponent"
                        :label="$t('erp.lang_ingredientComponent')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox :label="this.$t('erp.lang_dishOfTheDayControl')"
                                v-model="form.isDailyGoods" :true-value="1" :false-value="0" @change="updateIsDailyGoodsStatus"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>


            <v-tab-item>
              <image-cropper ref="ingredientImage" v-model="form.productImageUpload"/>
            </v-tab-item>

            <v-tab-item class="pa-2">
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field :label="this.$t('erp.lang_warecreate_biozertno')"
                                outlined
                                v-model="form.biozertnr"/>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field :label="this.$t('erp.lang_warecreate_biotext')"
                                outlined
                                v-model="form.BioText"/>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-checkbox :label="this.$t('erp.lang_warecreate_bioware')" class="ma-0"
                              v-model="form.bio_yes_no" value="1"/>
                </v-col>

                <v-col class="ma-0 pa-0" cols="12">
                  <v-divider class="ma-0 pa-0"/>
                </v-col>

                <v-col cols="12">
                  <v-expansion-panels focusable mandatory>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>{{ this.$t('erp.lang_Zusatzstoffe') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>

                        <v-row>
                          <v-col :key="i" cols="12" md="4"
                                 v-for="(elt , i) in this.zusaetze">
                            <v-switch :label="elt[1]" :value="elt[0].toString()"
                                      v-model="form.zusaetze"/>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- 2 -->
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>{{ this.$t('erp.lang_Allergene') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col :key="i" cols="12" md="4"
                                 v-for="(elt , i) in this.allergene">
                            <v-switch :label="elt[1]" :value="elt[0].toString()"
                                      v-model="form.allergene"/>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="controlsystem">
              <control-system-component
                  v-if="(this.variations && this.variations == 0) || this.variations === undefined || this.variations === null"/>
              <b-card class="shadow-none" no-body v-else>
                <b-tabs card>
                  <b-tab active class="pa-0 ma-0" title="Main Item">
                    <b-card-text class="pa-0 ma-0">
                      <control-system-component/>
                    </b-card-text>
                  </b-tab>
                  <b-tab class="pa-0 ma-0" title="Variations">
                    <b-card-text>
                      <v-expansion-panels>
                        <v-expansion-panel
                            :key="i"
                            v-for="(item,i) in this.variations"
                        >
                          <v-expansion-panel-header>{{ item.name }} ({{ item.identcode }})</v-expansion-panel-header>
                          <v-expansion-panel-content class="pa-0 ma-0">
                            <control-system-component v-bind:item-id="item.id"/>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <v-divider class="pa-0 ma-0"/>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </v-tab-item>
            <v-tab-item key="info">
              <v-card-title>{{$t('erp.lang_nav_recip')}}</v-card-title>
              <v-card-text v-if="!loading">
                <quill-editor v-model="recipes"></quill-editor>
              </v-card-text>

              <v-card-text v-if="loading" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-card-text>
            </v-tab-item>
            <v-tab-item key="comp" >
              <v-row class="pl-15">
                <v-col
                    class="d-flex"
                    cols="12"
                    sm="5"
                >
                  <v-select
                      :label="$t('generic.lang_filterByGroup')"
                      outlined
                      :items="IngredientGroups"
                      item-text="name"
                      item-value="uuid"
                      v-model = "ingFilter"
                      @input="filterIngredient"
                  ></v-select>
                </v-col>
                <v-col
                    class="d-flex"
                    cols="12"
                    sm="5"
                >
                  <v-autocomplete
                      :label="$t('erp.lang_ingredient')"
                      :readonly="false"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      item-text="name"
                      :items="ingredientsArray"
                      return-object
                      deletable-chips
                      outlined v-model="form.ingredientGroup"
                  ></v-autocomplete>
                </v-col>
                <v-col
                    class="d-flex"
                    cols="12"
                    sm="2"
                >
                  <v-btn
                      class="mx-2"
                      fab
                      small
                      dark
                      color="indigo"
                      @click="addIngredient"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-list
                  class="mx-auto"
                  max-width="90%"
              >
                <v-row justify="center">
                  <v-expansion-panels focusable>
                    <v-expansion-panel
                        v-for="(item,i) in form.SubIngredients"
                        :key="i"
                    >
                      <v-expansion-panel-header color="grey lighten-5"><H5> {{ $t('generic.lang_name') }} : {{item.name}}</H5>
                        <v-col cols="12" sm="4"  align="right">
                            <v-btn
                                small
                                icon
                                color="red"
                                @click="deleteType(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                        <template v-slot:actions>
                          <v-icon color="primary">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th class="text-left">
                                {{ $t('generic.lang_name') }}
                              </th>
                              <th class="text-left">
                                {{ $t('erp.lang_receiptUsedAmount') }}
                              </th>
                              <th class="text-left">
                               {{ $t('erp.lang_receiptWastedAmount') }}
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(meal) in meals"
                                :key="meal.id"
                            >
                              <td>{{ meal.name }}</td>
                              <td><v-col cols="12" md="12" sm="12">
                                <v-text-field :label="$t('erp.lang_receiptUsed')"
                                              outlined
                                              dense
                                              :suffix="item.unit_sign"
                                              v-model="item.wasted[meal.id]"

                                />
                              </v-col>
                              </td>
                              <td><v-col cols="12" md="12" sm="12">
                                <v-text-field :label="$t('erp.lang_wastedReceipt')"
                                              outlined
                                              dense
                                              :suffix="item.unit_sign"
                                              v-model="item.used[meal.id]"

                                />
                              </v-col>
                              </td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-list>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>

      <v-card-actions class="text-right">
        <v-spacer/>
        <v-btn :disabled="this.tabs == 0" @click="--tabs" class="ma-1" color="error" icon>
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-btn :disabled="loading || loadingGO" :loading="loading" @click="update()"
               class="ma-1" color="primary">
          {{$t('generic.lang_edit')}}
        </v-btn>

        <v-btn :disabled="this.tabs == 2" @click="++tabs" class="ma-1" color="primary" icon>
          <v-icon>arrow_forward</v-icon>
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                            :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"
                            id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';

import Quill from 'quill';
import {ImageDrop} from 'quill-image-drop-module';
Quill.register('modules/imageDrop', ImageDrop);

import {library} from '@fortawesome/fontawesome-svg-core';
import {faBarcode, faCocktail, faGifts, faMeat} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
//configs
import {ENDPOINTS} from "../../../../config";

import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns";
import Swatches from "vue-swatches";
import FontawesomePicker from "@/components/common/iconPicker";
import ControlSystemComponent from "../edititem/ControlSystemComponent";
import ItemRecipesEditor from "../../baseData/itemrecipes/ItemRecipesEditor";
import ImageCropper from "@/components/common/imagecropper";

library.add(
    faCocktail, faMeat, faGifts, faBarcode
);
export default {
  name: "IngredientEditComponent",
  components: {
    Swatches,
    FontawesomePicker,
    ControlSystemComponent,
    ItemRecipesEditor,
    ImageCropper,
    quillEditor,
    'font-awesome-icon': FontAwesomeIcon
  },
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      showIngredientCreate: false,
      loadingIsDailyGoods: false,
      loadingCreatIngredientGroup: false,
      gotoTo: false,
      loadingGO: false,
      loading: false,
      loadingEAN: false,
      loadingCheck: false,
      duplicatedEAN: false,
      loadingCheckInternID: false,
      duplicatedInternID: false,
      IngredientGroups: [],
      variations: [],
      tabs: 0,
      recipes:null,
      model : null,
      loadingSave: false,
      SellUnit:null,
      units : [],
      ingredients :[],
      meals : [],
      ingredientsArray : [],
      ingFilter : '',
      is_IngredientComponent2 : false,
      varriations: [

      ],
      newIngredientGroup: {
        name: null,
        color: null,
        icon: null
      },
      form: {
        gastroFoodType: 1,
        ware_ID: "",
        ware_buypriceNet: "",
        ware_internalNo: "",
        ware_name: "",
        editItemStorageQTY: "",
        productImageUpload: "",
        allergene: [],
        IngredientGroups: null,
        zusaetze: [],
        is_IngredientComponent: false,
        SellUnit:null,
        unitOfPurchase:null,
        sellUnitValue:null,
        SubIngredients : [],
        editItemID : null,

      },
      zusaetze: null,
      allergene: null
    }
  },
  mounted() {
    this.loading = true;
    this.loadItemValidation();
    this.getIngredientGroups();
    this.loadData();
    this.getUnits();
    this.loadMeals();
    this.filterIngredient();


    this.axios.post(ENDPOINTS.ERP.ITEM.RECIPE.GET, {
      itemID: this.$route.params.id
    }).then((res) => {
      if(res.data.success) {
        let recipeHTML = res.data.ware.recipeHTML;

        if(recipeHTML !== null)
          this.recipes = recipeHTML;
      }

      this.loading = false;
    }).catch(() => {
      this.loading = false;
    })
  },
  computed: {
    mainItemId: function () {
      return this.$route.params.id;
    },
    allergeneIDs() {
      let ids = [];

      this.allergene.forEach((allergen) => {
        if (allergen.selected) {
          ids.push(allergen.key);
        }
      });

      return ids;
    },
    additivesIDs() {
      let ids = [];

      this.additives.forEach((additive) => {
        if (additive.selected) {
          ids.push(additive.key);
        }
      });

      return ids;
    },


  },
  methods: {
    updateIsDailyGoodsStatus() {
      this.axios
          .post(ENDPOINTS.ERP.DAILYGOODS.UPDATE.ISDAILYGOODS, {
            editItemID: this.$route.params.id,
            isDailyGoods: this.form.isDailyGoods,
          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: res.data.message,
                color: "success",
              });
            } else {
              Events.$emit("showSnackbar", {
                message:
                    this.$t("generic.lang_errorOccurred") +
                    " : " +
                    (res.data.message || res.data.status),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred") + ": " + err.message,
              color: "error",
            });
          })
    },
    filteredUnits(){
      if(!this.units || !this.units.length){
        return [];
      }
      let filtered=[];
      filtered=this.units.map( unit => {
        if(!unit && unit.unit_name.length === 0)
          return;

        if(unit.unit_name.includes("lang_"))
          unit.unit_name = this.$t('erp.'+unit.unit_name);

        return unit;
      })
      return filtered;
    },
    getUnits(){
      this.axios.post(ENDPOINTS.ERP.UNITS.GET).then((res) => {
        if(res.data.STATUS==='SUCCESS'){
          this.units=res.data.units;
          this.units = this.filteredUnits();
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+ ' ' + this.$t('erp.lang_whileGettingUnits'),
          color: "error"
        });
      });
    },
    showImagePrompt(command) {
      const src = prompt('Enter the url of your image here')
      if (src !== null) {
        command({ src })
      }
    },
    saveRecipe() {
      this.loadingSave = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.RECIPE.UPDATE, {
        itemID: this.$route.params.id,
        recipeHTML: ""
      }).then((res) => {
        if(res.data.success) {
          this.$emit('goBack');
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    showIngredientGroup() {
      this.showIngredientCreate = true
    },
    getIngredientGroups() {
      this.axios.post(ENDPOINTS.ERP.INGRREDIENTGROUP.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.IngredientGroups = res.data.groups;
        }
      }).catch(err => {

      })
    },
    createIngredientGroups(){
      this.axios.post(ENDPOINTS.ERP.INGRREDIENTGROUP.CREATE, this.newIngredientGroup).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_ingredientGroupCreated'),
            color: "success"
          });
          this.getIngredientGroups();
          this.showIngredientCreate = false
          this.$refs.form.reset()
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: res.data.msg || res.data.status
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    loadItemValidation() {
      this.axios.post(ENDPOINTS.ERP.ITEM.VARIATIONS.GET, {
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.variations = res.data.variations;
        }
      })
    },
    loadMeals() {
      this.axios.post('get/indexeddb/mealSizes/', {
      }).then((res) => {
        this.meals = res.data
        this.meals.unshift({id: 0, name: "Standard Size", sorting: 0});
      })
    },
    filterIngredient() {
      this.axios.post('get/erp/ingredient/byGroup/', {
        ingredientGrp : this.ingFilter
      }).then((res) => {
        this.ingredientsArray = res.data.ingredient == null ? [] : res.data.ingredient
      })
    },
    addIngredient() {
      if(this.form.ingredientGroup.name!=""){
        this.form.SubIngredients.push({id : this.form.ingredientGroup.id,
          sellUnit :this.form.ingredientGroup.selling_unit ,unit_name :this.form.ingredientGroup.selling_unit_name ,unit_sign :this.form.ingredientGroup.selling_unit_sign ,
          name:this.form.ingredientGroup.name,wasted : {},used:{}});
        this.form.ingredientGroup = "";
        this.ingFilter="";
      }

    },
    update() {
      this.saveRecipe();
      if (this.$refs.hasOwnProperty('ingredientImage') &&this.$refs.ingredientImage?.getCroppedCanvas() !== null)
        this.$refs.ingredientImage.cropImage();

      if (!this.$refs.general.validate()) {
        this.tabs = 0;
        return;
      }

      this.loadingGO = this.gotoTo;
      this.loading = !this.loadingGO;

      let fd = new FormData();

      for (const key in this.form) {
        if (this.form.hasOwnProperty(key) && (this.form[key] === null || this.form[key] === undefined || this.form[key] === ""))
          delete this.form[key];
        else {
          if ((key === "allergene" || key === "zusaetze") && this.form[key].length > 0) {
            this.form[key].forEach(elt => {
              fd.append(key + "[]", elt)
            });
          } else
            fd.append(key, this.form[key])
        }
      }

      fd.append('editItemID', this.$route.params.id);
      this.form.editItemID = this.$route.params.id;

      this.axios.post(ENDPOINTS.ERP.INGRREDIENT.UPDATE, this.form).then((res) => {

        if (res.data) {
          this.tabs = 0;
          //this.$refs.form.reset();
          //this.$refs.general.reset();
          this.$router.push('/erp/baseData/ingredients');
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingGO = this.loading = false;
      });
    },
    checkEAN() {
      this.loadingCheck = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEEAN, {
        itemId: this.$route.params.id,
        barcode: this.form.ware_ID
      }).then((res) => {
        this.duplicatedEAN = res.data.success;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCheck = false;
      })
    },
    checkInternID() {
      this.loadingCheckInternID = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEINTERNALID, {
        itemId: this.$route.params.id,
        internalId: this.form.ware_internalNo
      }).then((res) => {
        this.duplicatedInternID = res.data.success;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCheckInternID = false;
      })
    },
    createEAN() {
      this.loadingEAN = true;
      this.axios.post(ENDPOINTS.ERP.INGRREDIENT.CREATEAN).then((res) => {
        this.form.ware_ID = res.data[0]
      }).finally(() => {
        this.loadingEAN = false;
      });
    },

    loadData() {
      this.axios.post(ENDPOINTS.ERP.INGRREDIENT.GET, {
        editItemID: this.$route.params.id
      }).then((res) => {

        this.zusaetze = res.data.additivesArr;
        this.allergene = res.data.allergensArr;
        this.form.allergene = res.data.allergensArrChecker;
        this.form.zusaetze = res.data.additivesArrChecker;
        this.form.ingredientGroup = res.data.GroupUUID;
        this.form.is_IngredientComponent = res.data.Is_ingredient_component;
        this.is_IngredientComponent2 = res.data.Is_ingredient_component;
        this.form.isDailyGoods = res.data.formfillData.checkBoxData.isDailyGoods;
        this.form.SubIngredients = res.data.ingredients;

        this.form.SellUnit = res.data.SellUnit;
        this.form.productImageUpload = res.data.imageData[1];

        for (let attr in res.data.formfillData.textFields) {
          if (this.form.hasOwnProperty(attr)) {
            this.form[attr] = res.data.formfillData.textFields[attr];
          }
        }

        this.form.biozertnr = res.data.formfillData.textFields.biozertNo;
        this.form.BioText = res.data.formfillData.textFields.bioText;
        this.form.bio_yes_no = res.data.formfillData.textFields.isBio;

        /* PRECHECK REQUIRED EAN AND INTERNAL ID CHECK */
        this.checkEAN();
        this.checkInternID();
      });
    },
    deleteType(item) {
      this.$swal({
        title: this.$t('erp.lang_deleteIngredient'),
        text: this.$t('erp.lang_deleteIngredientBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.form.SubIngredients.splice(this.form.SubIngredients.indexOf(item), 1);

        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    decimalNumbers(evt, val) {
      if(isNaN(evt.key) && evt.key !== '.' && evt.key !== ',' && evt.key !== '-' && evt.key !== 'Backspace') {
        evt.preventDefault();
      } else {
        if ((evt.key == '-' && val.match(/-/g) || []).length > 1 || (evt.key == '.' && val.indexOf(".") > -1)) {
          evt.preventDefault();
        }
      }
    },
  },
}
</script>
<style scoped>
#child {
  height: 50%;
  width: 75%;
}
</style>
