<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_ingredients') "
                :icon="icon"
                :subheading="$t('erp.lang_nav_wareedit')"
                show-previous-button
                url-previous="/erp/baseData/ingredients"
        ></page-title>
        <div class="app-main__inner">
            <ingredient-edit-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import IngredientEditComponent from "../../../../components/erp/baseData/ingredients/IngredientEditComponent";

    export default {
        name: "Ingredient",
        components: {
            IngredientEditComponent,
            PageTitle,

        },
        data: () => ({
            icon: "mdi mdi-shaker-outline icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>